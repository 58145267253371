import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


import AppPageHeader from '@/views/layout/AppPageHeader.vue'
import AppLoader from '@/components/Loader.vue'
import './registerServiceWorker'
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import { defaultAppSettings, getUserApplicationRole } from '@/helpers/utilities'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

var CryptoJS = require('crypto-js')

Vue.component('app-page-header', AppPageHeader)
Vue.component('app-loader', AppLoader)
Vue.use(PerfectScrollbar)
Vue.use(JsonViewer)

Vue.component('GmapCluster', GmapCluster)


Vue.use(VueGoogleMaps, {
  load: {
    //key: 'AIzaSyD5UyIVZC25esg45RrCVg0w5kfdScDN4OI',
    key: 'AIzaSyCzbYj5sgCctZ9UVTs4ImQWbvpc7-xkMEI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})



// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  //window.console.log("Authenticated: " + store.getters.authenticated);
  //window.console.log("Application user role: " + getUserApplicationRole());

  if(to.matched.some(record => record.meta.requiresAuth) && (store.getters.authenticated == false) ) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  //} else if ( (to.name == 'login' || to.name == 'passwordResetRequest' || to.name == 'passwordReset') && store.state.user.access_token) {
  } else if ( (to.name == 'login' || to.name == 'passwordResetRequest' || to.name == 'passwordReset') && store.getters.authenticated) {
    return router.push({
      name: 'invoices'
    })
  } else if(to.path == '/') {
    return router.push({
      name: 'invoices'
    })
  } else {
      return next()
  }
})

// Check local storage to handle refreshes
if (window.localStorage) {
  var userToRemove = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY_OLD)) || null

  if(userToRemove) {
    localStorage.removeItem(process.env.VUE_APP_KEY_OLD)
  }

  var oldSettings = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_SETTINGS_OLD)) || null
  if(oldSettings) {
    localStorage.removeItem(process.env.VUE_APP_SETTINGS_OLD)
  }

  var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
  if (user) {
    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    //window.console.log("### main ###")
    //window.console.log(decryptedData)

    user = decryptedData
    if( (store.state.user.id !== user.id)) {
      store.commit('SET_USER', user)
      store.commit('SET_AUTHENTICATED', true)
    } 
    
  }

  let appSettings = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_SETTINGS_KEY)) || null
  if(appSettings) {
    //window.console.log("App settings: ")
    //window.console.log(appSettings)
    store.commit('SET_STATE_APP_SETTINGS', appSettings)

  } else {
    window.console.log("App settings not set.")
    let appSettings = defaultAppSettings();
    store.commit('SET_DEFAULT_APP_SETTINGS', appSettings)

  }
}

//event bus
export const EventBus = new Vue();

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
